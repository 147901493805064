import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import AdminLayout from './layouts/AdminLayout';
import PublicLayout from './layouts/PublicLayout';
import Login from './public_pages/Login';
import Dashboard from './components/admin_components/Dashboard';
import TronDashboard from './components/admin_components/TronDashboard';
import TronProject from './components/admin_components/TronProject';


function App() {
  return (
    <Router>
        <AuthProvider>
          <Routes>

            <Route element={<PublicLayout />}>
              <Route path="/" element={<Login />} />
            </Route>

            <Route element={<AdminLayout />}>
              <Route path="/dashboard" element={<Dashboard />} /> 
              <Route path="/tron" element={<TronDashboard />} /> 
              <Route path="/projects/:id" element={<TronProject />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />

          </Routes>
        </AuthProvider>
      </Router>
  );
}

export default App;
