import React, {useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SquareCheck, PiggyBank } from 'lucide-react';
import { useFetchWithToken } from '../../services/api';

const TronDashboard = () => {
  const navigate = useNavigate();
  const fetchWithToken = useFetchWithToken();
    const [isCreateProjectFormVisible, setCreateProjectFormVisible] = useState(false);
    const [createParentTronWalletForm, setCreateParentTronWalletForm] = useState(false);
    const [parentWallets, setParentWallets] = useState([]);
    const [tronProjects, setTronProjects] = useState([])

  //  _______________



  const [copied, setCopied] = useState(null);

  const handleCopyToClipboard = (address) => {
    navigator.clipboard.writeText(address)
      .then(() => {
        setCopied(address);  // Set copied state to the address that was copied
        setTimeout(() => setCopied(null), 2000);  // Reset the copied state after 2 seconds
      })
      .catch(err => {
        console.error('Failed to copy address: ', err);
      });
  };

 const navigateToProject = (id) => {
  navigate(`/projects/${id}`);
 }

  // _________________
  
  const fetchParentWallets = async () => {
    try {
      const response = await fetchWithToken('/api/parent-wallets/'); 
      const data = await response.json();
      setParentWallets(data);
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  const fetchTronProjects = async () => {
    try {
      const response = await fetchWithToken('/api/projects/'); 
      const data = await response.json();
      console.log('tron projects:', data)
      setTronProjects(data);
    } catch (error) {
      console.error('Error fetching wallets:', error);
    }
  };

  useEffect(() => {
    fetchParentWallets();
    fetchTronProjects();
  }, []);

//_________________________________________________________________________________________

  const [createTronProjectformData, setCreateTronProjectFormData] = useState({
    name: '',
    numberOfWallets: '',
    timeGapMin: '',
    timeGapMax: '',
    priceRangeMin: '',
    priceRangeMax: '',
    parentWallet: '',
    target_address:''
  });

  const handleCreateProjectToggleForm = () => {
    setCreateProjectFormVisible(!isCreateProjectFormVisible);
  };

  const handleTronProjectFormChange = (e) => {
    setCreateTronProjectFormData({
      ...createTronProjectformData,
      [e.target.name]: e.target.value
    });
  };

  const handleCreateTronProjectSubmit = async (e) => {
    e.preventDefault();
    console.log(createTronProjectformData);

    const payload = {
        name: createTronProjectformData.name,
        parent_wallet: parseInt(createTronProjectformData.parentWallet, 10), // Assuming this is the address or ID of the selected wallet
        number_wallets: parseInt(createTronProjectformData.numberOfWallets, 10),
        time_gap_min: parseInt(createTronProjectformData.timeGapMin, 10),
        time_gap_max: parseInt(createTronProjectformData.timeGapMax, 10),
        transaction_min: parseInt(createTronProjectformData.priceRangeMin, 10),
        transaction_max: parseInt(createTronProjectformData.priceRangeMax, 10),
        target_address:createTronProjectformData.target_address
      };
    // You can add functionality to submit the form data to the server here
    console.log(payload);
    try {
        // Send a POST request to create a new TronProject
        const response = await fetchWithToken('/api/projects/', {  // Replace with your actual endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log('Project created successfully:', result);
          navigate(`/projects/${result.id}`);
          // Optionally, reset the form or navigate to another page
          setCreateTronProjectFormData({
            name: '',
            numberOfWallets: '',
            timeGapMin: '',
            timeGapMax: '',
            priceRangeMin: '',
            priceRangeMax: '',
            parentWallet: ''
          });
          setCreateProjectFormVisible(false);  // Hide the form after successful submission
  
          // Navigate to the project page (if you have routing set up)
          // navigate(`/projects/${result.id}`);
        } else {
          // Handle errors if the response is not OK
          const errorData = await response.json();
          console.error('Error creating project:', errorData);
          alert('Failed to create project: ' + (errorData.detail || 'Unknown error'));
        }
      } catch (error) {
        console.error('Error creating project:', error);
        alert('An error occurred while creating the project. Please try again.');
      }
  };


//_________________________________________________________________________________________

  const [parentTronformData, setParentTronFormData] = useState({
    name: '',
  });

  const handleCreateParentTwonWalletToggleForm = () => {
    setCreateParentTronWalletForm(!createParentTronWalletForm);
  };

  const handleParentTronWalletFormChange = (e) => {
    setParentTronFormData({
      ...parentTronformData,
      [e.target.name]: e.target.value
    });
  };

  const handleCreateParentTronWalletSubmit = async (e) => {
    e.preventDefault();
    console.log(parentTronformData);

    const payload = {
        name: parentTronformData.name,
      };
    // You can add functionality to submit the form data to the server here

    try {
        // Send a POST request to create a new TronProject
        const response = await fetchWithToken('/api/parent-wallets/', {  // Replace with your actual endpoint
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });
  
        if (response.ok) {
          const result = await response.json();
          console.log('Project created successfully:', result);
  
          // Optionally, reset the form or navigate to another page
          setParentTronFormData({
            name: '',
          });
          setCreateParentTronWalletForm(false);  // Hide the form after successful submission
          fetchParentWallets();
  
          // Navigate to the project page (if you have routing set up)
          // navigate(`/projects/${result.id}`);
        } else {
          // Handle errors if the response is not OK
          const errorData = await response.json();
          console.error('Error creating project:', errorData);
          alert('Failed to create project: ' + (errorData.detail || 'Unknown error'));
        }
      } catch (error) {
        console.error('Error creating project:', error);
        alert('An error occurred while creating the project. Please try again.');
      }
  };

//__________________________________________________________________________________


  return (
    <div>
    <h1 className='text-gray-300 text-4xl mb-12'>Tron Dashboard</h1>

      <h1 className='text-red-500'>dleet porject optiona dn delet aprent wallet option, /hide option</h1>
      {/* Create Tron Projects */}
      <div className='p-12 text-gray-300 bg-slate-900/70 mb-12 rounded-2xl'>
        
        {/* Button to toggle form visibility */}
        <button className='bg-indigo-600 p-4 mb-6 rounded-lg' onClick={handleCreateProjectToggleForm}>
          Create Tron Project
        </button>
        {/* Conditionally render the form based on isFormVisible state */}
        {isCreateProjectFormVisible && (
          <div className='bg-indigo-600 p-12 rounded-lg'>
            <form onSubmit={handleCreateTronProjectSubmit}>
              {/* Name input */}
              <div className='mb-4'>
                <label>Name:</label>
                <input 
                  className='bg-gray-600'
                  type="text" 
                  name="name" 
                  value={createTronProjectformData.name} 
                  onChange={handleTronProjectFormChange} 
                />
              </div>

              {/* Parent Wallet select input */}
              <div className='mb-4'>
                <label>Parent Wallet:</label>
                <select 
                  name="parentWallet"
                  className='bg-gray-600' 
                  value={createTronProjectformData.parentWallet} 
                  onChange={handleTronProjectFormChange}
                >
                  <option value="">Select a wallet</option>
                  {parentWallets.map((wallet) => (
                    <option key={wallet.id} value={wallet.id}>
                      {wallet.name || wallet.id}
                    </option>
                  ))}
                </select>
              </div>
              
              {/* Number of wallets input */}
              <div className='mb-4'>
                <label>Number of Wallets:</label>
                <input 
                  
                  className='bg-gray-600'
                  type="number" 
                  name="numberOfWallets" 
                  value={createTronProjectformData.numberOfWallets} 
                  onChange={handleTronProjectFormChange} 
                />
              </div>
              
              {/* Time gap range inputs */}
              <div className='flex mb-4'>
                <label>Time Gap Range (Seconds):</label>
                <div className='space-x-4'>
                  <input
                    className='bg-gray-600' 
                    type="number" 
                    name="timeGapMin" 
                    placeholder="Min" 
                    value={createTronProjectformData.timeGapMin} 
                    onChange={handleTronProjectFormChange} 
                  />
                  <input 
                    className='bg-gray-600'
                    type="number" 
                    name="timeGapMax" 
                    placeholder="Max" 
                    value={createTronProjectformData.timeGapMax} 
                    onChange={handleTronProjectFormChange} 
                  />
                </div>
              </div>
              
              {/* Price range inputs */}
              <div className='flex mb-4'>
                <label>Price Range:</label>
                <div className='space-x-4'>
                  <input 
                    className='bg-gray-600'
                    type="number" 
                    name="priceRangeMin" 
                    placeholder="Min" 
                    value={createTronProjectformData.priceRangeMin} 
                    onChange={handleTronProjectFormChange} 
                  />
                  <input
                    className='bg-gray-600' 
                    type="number" 
                    name="priceRangeMax" 
                    placeholder="Max" 
                    value={createTronProjectformData.priceRangeMax} 
                    onChange={handleTronProjectFormChange} 
                  />
                </div>
              </div>

                  {/* target address/token */}
              <div className='mb-4'>
                <label>Target:</label>
                <input 
                  className='bg-gray-600'
                  type="text" 
                  name="target_address" 
                  value={createTronProjectformData.target_address} 
                  onChange={handleTronProjectFormChange} 
                />
              </div>
              
              {/* Submit button */}
              <div className='pt-12'>
                <button type="submit" className='p-4 rounded bg-blue-950'>Submit</button>
              </div>
            </form>
          </div>
        )}



        {/* All Tron Projects */}
      <div>
      <div>
        <div>

        </div>
      </div>
      <div>
        <h1>Tron Projects</h1>
        <table>
          <thead>
            <tr className='border border-slate-700 px-4'>
              <th className='border border-slate-700 px-4'>Name</th>
              <th className='border border-slate-700 px-4'>Created</th>
              <th className='border border-slate-700 px-4'>Parent Wallet</th>
              {/* <th className='border border-slate-700 px-4'>Created At</th> */}
              <th className='border border-slate-700 px-4'>Target</th>
              <th className='border border-slate-700 px-4'>Action</th> {/* Extra column for the button */}
            </tr>
          </thead>
          <tbody>
            {tronProjects.map((tronProject) => (
              <tr className='border border-slate-700 px-4' key={tronProject.id}>
                <td className='border border-slate-700 px-4'>{tronProject.name}</td>
                <td className='border border-slate-700 px-4'>{tronProject.created_at}</td>
                <td className='border border-slate-700 px-4'>{tronProject.parent_wallet}</td>
                {/* <td className='border border-slate-700 px-4'>{new Date(tronProject.created_at).toLocaleString()}</td> */}
                <td className='border border-slate-700 px-4'>{tronProject.target_address}</td>
                <td className='border border-slate-700 px-4'>
                  {/* Placeholder button with Lucide icon */}
                  <button onClick={() => navigateToProject(tronProject.id)} >
                    <PiggyBank /> {/* This will display the Lucide 'Edit' icon */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      </div>
      </div>



      {/* Parent Tron Wallets */}
      <div className='text-gray-300 bg-slate-900/70 p-12 rounded-2xl mb-12'>
      <div>
        <div>
          <button className='bg-indigo-600 p-4 mb-6 rounded-lg' onClick={handleCreateParentTwonWalletToggleForm}>
            Create Parent Tron Wallet
          </button>
          {createParentTronWalletForm && (
          <div className='bg-indigo-600 p-12 rounded-lg'>
            <form onSubmit={handleCreateParentTronWalletSubmit}>
              {/* Name input */}
              <div className='mb-4'>
                <label>Name:</label>
                <input 
                  className='bg-gray-600 px-4'
                  type="text" 
                  name="name" 
                  value={parentTronformData.name} 
                  onChange={handleParentTronWalletFormChange} 
                />
              </div>
              <div className='pt-12'>
                <button type="submit" className='p-4 rounded bg-blue-950'>Submit</button>
              </div>
            </form>
          </div>
        )}
        </div>
      </div>
      <div>
        <h1>Parent Tron Wallets</h1>
        <table>
          <thead>
            <tr className='border border-slate-700 px-4'>
              <th className='border border-slate-700 px-4'>Name</th>
              <th className='border border-slate-700 px-4'>Address</th>
              <th className='border border-slate-700 px-4'>Private Key</th>
              <th className='border border-slate-700 px-4'>Created At</th>
              <th className='border border-slate-700 px-4'>Status</th>
              <th className='border border-slate-700 px-4'>Action</th> {/* Extra column for the button */}
            </tr>
          </thead>
          <tbody>
            {parentWallets.map((wallet) => (
              <tr className='border border-slate-700 px-4' key={wallet.id}>
                <td className='border border-slate-700 px-4'>{wallet.name}</td>
                <td className='border border-slate-700 px-4'>{wallet.address}</td>
                <td className='border border-slate-700 px-4'>{wallet.private_key}</td>
                <td className='border border-slate-700 px-4'>{new Date(wallet.created_at).toLocaleString()}</td>
                <td className='border border-slate-700 px-4'>{wallet.status ? 'Good' : 'Bad'}</td>
                <td className='border border-slate-700 px-4'>
                  {/* Placeholder button with Lucide icon */}
                  <button>
                    <PiggyBank /> {/* This will display the Lucide 'Edit' icon */}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      </div>


    
    
    </div>

        
  );
};

export default TronDashboard;
