import React, {useState} from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [error, setError] = useState('');
  const [waitingApprovalError, setWaitingApprovalError] = useState('');
  const { login } = useAuth();
  // const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client Side Validation
    // Make sure email and password are included
    if (!formData.email || !formData.password) {
        setError('Please enetr you email and password.');
        return;
    }
    // Make sure email is in proepr format, test againt regex format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)){
        setError('Please enter a valid email address.');
        return;
    }
    // Make sure email doesnt conatin any specific characters
    const forbiddenCharsEmail = /[<>]/;
    if (forbiddenCharsEmail.test(formData.email)){
        setError('Email address contains invalid characters');
        return;
    }
    // Make sure password is prper format, no dissallowed characters
    if (forbiddenCharsEmail.test(formData.password)){
        setError('Password should not contain certain characters: <, >.');
        return;
    }
    // Clear any existing errors
    setError('');
    setWaitingApprovalError('');

    try {
        const response = await fetch(`${LOGIN_URL}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...formData, rememberMe }), // Include rememberMe
            credentials: 'include', // Include credentials in the request
        });

        if (response.ok) {
            const data = await response.json();
            console.log("Login successful:", data);
            localStorage.setItem('persistbeacon', data.persistbeacon);
            localStorage.setItem('userRole', data.userRole);
            login(); 
        } else {
            // const errorData = await response.json();
            // setError(errorData.detail || 'Login failed. Please try again.');
            // console.error("Login failed:", errorData);
            const errorData = await response.json();
            if (response.status === 403 && errorData.error_code === 'ACCOUNT_PENDING_APPROVAL') {
                setWaitingApprovalError('Your account is awaiting approval.');
            } else {
                setError(errorData.detail || 'Login failed. Please try again.');
            }
            console.error("Login failed:", errorData)
        }
    } catch (error) {
        console.error('Error during login:', error);
        setError('An error occurred during login. Please try again.');
    }
};



  return (
      <div className="min-h-screen w-full flex justify-center">

          <div className="w-full max-w-md mt-32">
            {error && <p className="text-red-500">{error}</p>}
            {waitingApprovalError && <p className="text-green-500">{waitingApprovalError}</p>}
            <form onSubmit={handleSubmit} className="mb-4">
              <h2 className="text-5xl text-gray-200 font-bold text-left mb-6">OCHESTRA</h2>
              <h2 className="text-5xl text-gray-200 font-bold text-left mb-6">Log In</h2>
              <div className='mb-4'>
                <input
                  className="bg-transparent shadow appearance-none border border-gray-600 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline outline-cyan-500 focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete='email'
                />
              </div>
              <div className='mb-4'>
                <input
                  className="bg-transparent shadow appearance-none border border-gray-600 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline outline-cyan-500 focus:shadow-outline"
                  id="confirmPassword"
                  type="confirmPassword"
                  placeholder="Confirm Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                  autoComplete='current-password'
                />
              </div>

              <div className='flex justify-between mb-2 '>
                <div>
                  <input type="checkbox" id="rememberMe" name="rememberMe" checked={rememberMe} onChange={handleRememberMeChange} />
                  <label className="text-gray-400 ml-2" htmlFor="rememberMe">Remember Me</label>
                </div>
                {/* <div className='text-gray-400'><a>Forgot Password?</a></div> */}
              </div>

              <div>
                <button
                  className="bg-indigo-600 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="submit" 
                >
                  Log In
                </button>
              </div>
            </form>
          </div>
 
      </div>
    );
};

export default Login;
