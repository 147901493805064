import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { SquareCheck } from 'lucide-react';
import { useFetchWithToken } from '../../services/api';

const apiKey = process.env.REACT_APP_TRON_API_KEY;
//how do we get proejct data, w eoculd getit suign ftehc and params above
const TronProject = () => {
  const { id } = useParams();
  const fetchWithToken = useFetchWithToken();
    // const [parentWallet, setParentWallet] = useState([]);
    const [project, setProject ] = useState(null);
    const [walletBalances, setWalletBalances] = useState({});
    const [loading, setLoading] = useState(true);

    const fetchProjectDetails = async (id) => {
      try {
        const response = await fetchWithToken(`/api/projects/${id}/with_wallets/`);
        if (!response.ok) {
          throw new Error('Failed to fetch project details');
        }
        const data = await response.json();
        setProject(data);
        console.log('Fetched project with related wallets and parent wallet:', data);
        // Fetch all wallet balances after loading project data
        await fetchAllWalletBalances(data.wallets);
      } catch (error) {
        console.error('Error fetching project details:', error);
      } finally {
        setLoading(false); // Set loading to false when fetch is complete
      }
    };
    
    useEffect(() => {
      fetchProjectDetails(id);
    }, [id]); // Replace '1' with the actual project ID you want to fetch
    
    const fetchWalletBalance = async (address) => {
      try {
        const response = await fetch(`https://api.trongrid.io/v1/accounts/${address}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'TRON-PRO-API-KEY': apiKey
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch wallet balance');
        }
        const data = await response.json();

        // Log the entire response data to see what it contains
        console.log('API Response Data:', data);

        const balance = data.data[0] ? data.data[0].balance : 0; // If no balance found, return 0
        return balance / 1e6; // Convert from SUN to TRX
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
        return null;
      }
    };
  
    const fetchAllWalletBalances = async (wallets) => {
      const balances = {};
      for (const wallet of wallets) {
        const balance = await fetchWalletBalance(wallet.address);
        balances[wallet.id] = balance;
      }
      setWalletBalances(balances);
    };

    const handleUpdateBalances = async () => {
      if (project) {
        await fetchAllWalletBalances(project.wallets);
      }
    };

    const handleDumpAll = async () => {
      try {
        const response = await fetchWithToken('/api/dump_all/', {
          method: 'POST',
        });
        if (response.ok) {
          alert('All data dumped successfully.');
        } else {
          throw new Error('Failed to dump all data');
        }
      } catch (error) {
        console.error('Error dumping all data:', error);
        alert('Failed to dump all data.');
      }
    };

    const handleStartBuying = async () => {
      try {
        const response = await fetchWithToken(`/api/projects/${id}/start_buying/`, {
          method: 'POST',
        });
        if (response.ok) {
          alert('Orchestra started successfully.');
        } else {
          throw new Error('Failed to start Orchestra');
        }
      } catch (error) {
        console.error('Error starting Orchestra:', error);
        alert('Failed to start Orchestra.');
      }
    };

    const handleStartSelling = async () => {
      try {
        const response = await fetchWithToken(`/api/projects/${id}/start_selling/`, {
          method: 'POST',
        });
        if (response.ok) {
          alert('Started selling successfully.');
        } else {
          throw new Error('Failed to start selling');
        }
      } catch (error) {
        console.error('Error starting selling:', error);
        alert('Failed to start selling.');
      }
    };

    const handleWithdrawToParent = async () => {
      try {
        const response = await fetchWithToken(`/api/projects/${id}/withdraw_to_parent/`, {
          method: 'POST',
        });
        if (response.ok) {
          alert('Funds withdrawn to parent wallet successfully.');
        } else {
          throw new Error('Failed to withdraw to parent wallet');
        }
      } catch (error) {
        console.error('Error withdrawing to parent wallet:', error);
        alert('Failed to withdraw to parent wallet.');
      }
    };

    // Ensure we only try to render if 'project' has been fetched and has the required data
  if (loading || !project) {
    return <div>Loading...</div>; // Show loading text or spinner until data is loaded
  }



  return (
    <div>
      
      {/* Project Details */}
      <div className="p-12 text-gray-300 bg-slate-900/70 mb-12 rounded-2xl">
        <div className='flex items-end mb-2'>
          <p className='text-xl font-medium mr-12'>Project Name: {project.name}</p>
          <p>Created At: {new Date(project.created_at).toLocaleString()}</p>
        </div>
        
        <p className='border-b pb-2 border-slate-700 mb-2'>Number of Wallets: {project.number_wallets}</p>
        <p className='mb-2'>Time Gap Min: {project.time_gap_min}</p>
        <p className='border-b pb-2 border-slate-700 mb-2'>Time Gap Max: {project.time_gap_max}</p>
        <p className='mb-2'>Transaction Min: {project.transaction_min}</p>
        <p className='border-b mb-2 pb-2 border-slate-700'>Transaction Max: {project.transaction_max}</p>
        <p className='border-b mb-2 border-slate-700 pb-2'>Parent Wallet: {project.parent_wallet.name} ({project.parent_wallet.address})</p>
        <p className='border-b mb-2 border-slate-700 pb-2'>Status: {project.parent_wallet.status}</p>
      </div>

      <div className="flex mb-16 space-x-8 px-4">
        
        <button onClick={handleUpdateBalances} className="flex-1 p-2 bg-blue-500 text-white rounded">
          Update Balances
        </button>
        <button onClick={handleDumpAll} className="p-2 flex-1 bg-red-800 text-white rounded">
          Dump All
        </button>
        <button onClick={handleStartBuying} className="p-2 flex-1 bg-green-500 text-white rounded">
          Start Buying
        </button>
        <button onClick={handleStartSelling} className="p-2 flex-1 bg-red-500 text-white rounded">
          Start Selling
        </button>
        <button onClick={handleWithdrawToParent} className="p-2 flex-1 bg-indigo-500 text-white rounded">
          Withdraw to Parent
        </button>
      </div>

      {/* Target Address */}
      <div className="p-12 text-gray-300 bg-slate-900/70 mb-12 rounded-2xl">
        <h2>Target Address</h2>
        <p>{project.target_address}</p>
      </div>

      

      {/* Associated Wallets */}
      <div className="p-12 text-gray-300 bg-slate-900/70 mb-12 rounded-2xl">
        <h2>Project Wallets</h2>
        <table>
          <thead>
            <tr>
              <th className='border border-slate-700 px-4'>Address</th>
              <th className='border border-slate-700 px-4'>Private Key</th>
              <th className='border border-slate-700 px-4'>Created At</th>
              <th className='border border-slate-700 px-4'>Balance</th>
            </tr>
          </thead>
          <tbody>
            {project.wallets.map(wallet => (
              <tr key={wallet.id}>
                <td className='border border-slate-700 px-4'>{wallet.address}</td>
                <td className='border border-slate-700 px-4'>{wallet.private_key}</td>
                <td className='border border-slate-700 px-4'>{new Date(wallet.created_at).toLocaleString()}</td>
                <td className='border border-slate-700 px-4'>{walletBalances[wallet.id] !== undefined ? walletBalances[wallet.id] : 'Loading...'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

        
  );
};

export default TronProject;
