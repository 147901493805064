// src/components/Header.js
import React, { useState } from 'react';
import { Link, NavLink} from 'react-router-dom';
import { House, Handshake, Users } from 'lucide-react';
import useLogout from '../logout';

const AdminHeader = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleLogout = useLogout();

  return (
    <header className="bg-slate-900/70">
      <div className="">

        <div className='container mx-auto flex justify-between items-center'>

          <div className="text-lg font-medium text-white">
            ORCHESTRA
          </div>

          <div className='flex items-center'>

            <nav className='container mx-auto py-4'>
            <ul className="flex space-x-8">
              <li>
                <NavLink to="/dashboard" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                  <House size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/tron" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                  <Handshake size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Tron
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/staff" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                  <Users size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Staff
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink to="/dashboard" className="text-sectextGray  text-sm hover:text-blue-700">Dashboard</NavLink>
              </li> */}

            </ul>
            </nav>

            <div className='relative pl-20'>
              <div onClick={toggleDropdown} className="text-white text-sm whitespace-nowrap">Sign Out</div>
            
              {isOpen && (
              <div id="dropdown" className="absolute right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow whitespace-nowrap">
                <ul className="py-2 px-4 text-sm " aria-labelledby="dropdownDefaultButton">
      
                  <li>
                    <button onClick={handleLogout} className="block w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</button>
                  </li>
                </ul>
              </div>
              )}
            </div>

          </div>

        </div>
      </div>

      {/* <div className='bg-indigo-600'>
        <nav className='container mx-auto py-4'>
          <ul className="flex space-x-8">
            <li>
              <NavLink to="/dashboard" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <House size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to="/tron" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <Handshake size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Tron
              </NavLink>
            </li>
            <li>
              <NavLink to="/staff" className={({ isActive }) =>`text-gray-100 flex items-center text-sm hover:text-blue-700 ${isActive ? 'text-blue-700 ' : ''}`}>
                <Users size={16} color="currentColor" className="text-greyishtext hover:text-white mr-1"/>Staff
              </NavLink>
            </li>

          </ul>
        </nav>
      </div> */}
      
    </header>
  );
};

export default AdminHeader;
