import React from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <div>
      
      <div className='flex flex-col'>

        <h1 className='text-6xl text-gray-200 mb-8'>cunt</h1>

      </div>
    </div>
  );
};

export default Dashboard;
