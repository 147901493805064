import React from 'react';
import { Outlet } from 'react-router-dom';
import PublicHeader from '../components/public_components/HeaderPublic';

const PublicLayout = () => {
  return (
    <div className='bg-slate-950 min-h-screen flex flex-col'>
    {/* <PublicHeader /> */}
      <main className='flex flex-grow'>
        <Outlet />
      </main>
    </div>
  );
};

export default PublicLayout;
