import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import AdminHeader from '../components/admin_components/HeaderAdmin';

const AdminLayout = () => {

  return (
    <div className='bg-slate-800 text-text-Gray min-h-screen'>
      <AdminHeader />
      <main className='container min-h-screen py-8 mx-auto'>
        <Outlet />
      </main>
    </div>
  );
};

export default AdminLayout;
